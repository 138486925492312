<template>
<div class="patch-ma-in-operate" id="page">
  <el-row class="row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item prop="txtPatchName">
          <el-input v-model="form.txtPatchName" clearable placeholder="补丁名称">
            <template #prepend>
              <i class="iconfont icon-sousuo" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="dateRange">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">搜索</el-button>
          <el-button type="info" @click="reset()">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row>
      <el-button type="primary" @click="dialog.updateShow=true">新增补丁</el-button>
    </el-row>
  </el-row>
  <el-table :data="table.tableData" v-loading="table.tabelLoading">
    <el-table-column prop="txtPatchName" label="补丁名称" :show-overflow-tooltip="true" align="center">
      <template #default="scope">{{scope.row.txtPatchName||"- -"}}</template>
    </el-table-column>
    <el-table-column prop="txtDeviceTypeName" label="所属设备" align="center">
      <template #default="scope">
        <template v-if="scope.row.txtDeviceTypeName.length>40">
          <el-tooltip class="box-item" effect="dark" :content="scope.row.txtDeviceTypeName.slice(0, 50)+'...'" placement="top">
            {{ scope.row.txtDeviceTypeName.slice(0, 40) + "..." }}
          </el-tooltip>
        </template>
        <template v-else>{{ scope.row.txtDeviceTypeName||"- -" }}</template>
      </template>
    </el-table-column>
    <el-table-column prop="txtPatchDesc" label="内容说明" align="center">
      <template #default="scope">
        <template v-if="scope.row.txtPatchDesc.length>40">
          <el-tooltip class="box-item" effect="dark" :content="scope.row.txtPatchDesc.slice(0, 50)+'...'" placement="top">
            {{ scope.row.txtPatchDesc.slice(0, 40) + "..." }}
          </el-tooltip>
        </template>
        <template v-else>{{ scope.row.txtPatchDesc||"- -" }}</template>
      </template>
    </el-table-column>
    <el-table-column prop="dtCreate" label="创建时间" align="center" width="200">
      <template #default="scope">{{scope.row.dtCreate||"- -"}}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option" width="150">
      <template #default="scope">
        <div class="table-icon" @click="handleEdit(scope.row)">
          <el-icon>
            <EditPen />
          </el-icon> 编辑
        </div>
        <div class="table-icon" @click="handleDelete(scope.row)">
          <el-icon>
            <Delete />
          </el-icon> 删除
        </div>
      </template>
    </el-table-column>
  </el-table>
  <pagination :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
  <update-patch v-if="dialog.updateShow" :updateData="dialog.updateData" @close="(e) => {dialog.updateData={type:'add'};dialog.updateShow=false;if(e){getList()}}" />
</div>
</template>

<script>
// 运营管理 - 补丁程序管理
import { useStore } from 'vuex';
import { Pagination } from "components";
import { onMounted, reactive, toRefs } from "vue";
import UpdatePatch from "./comp/patch/updatePatch.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { EditPen, Delete } from "@element-plus/icons-vue";
import { getPatchListInPage, deletePatch } from "api/apis.js";
export default {
  components: {
    Pagination,
    UpdatePatch,
    EditPen,
    Delete,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      form: {
        txtPatchName: "",
        dateRange: [],
        limit: 10,
        page: 1
      },
      dialog: {
        updateShow: false,
        updateData: { type: "add" }
      },
      table: {
        tableData: [],
        total: 0,
        tabelLoading: false
      },
    })

    onMounted(() => {
      getList();
    })

    // 获取列表
    const getList = () => {
      state.table.tabelLoading = true;

      let param = {
        pageNum: state.form.page,
        pageSize: state.form.limit,
        query: {
          txtPatchName: state.form.txtPatchName,
          dtFrom: (state.form.dateRange || []).length == 0 ? "" : state.form.dateRange[0],
          dtTo: (state.form.dateRange || []).length == 0 ? "" : state.form.dateRange[1]
        }
      }

      getPatchListInPage(param).then(response => {
        if (response.code == 200) {
          state.table.tableData = response.data.list;
          state.table.total = response.data.total;
        } else {
          state.table.tableData = response.data.list;
          state.table.total = response.data.total;
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.tabelLoading = false;
      })
    };

    // 编辑
    const handleEdit = row => {
      state.dialog.updateData = row;
      state.dialog.updateData.type = "edit";
      state.dialog.updateShow = true;
    };

    // 删除
    const handleDelete = row => {
      ElMessageBox.confirm(
        `请确认是否删除当前补丁【${row.txtPatchName}】试剂？`,
        '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定'
        }
      ).then(() => {
        state.table.tabelLoading = true;
        // 调用 删除接口
        deletePatch({
          patchId: row.patchId,
          txtUpdater: store.getters.userInfo ? store.getters.userInfo.name : "系统用户"
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("删除成功");
            getList();
          } else {
            ElMessage.error(response.message);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.tabelLoading = false;
        })
      })
    }

    // 重置表单
    const reset = () => {
      state.form = {
        txtPatchName: "",
        dateRange: [],
        limit: 10,
        page: 1
      }
      getList();
    };

    return {
      ...toRefs(state),
      getList,
      reset,
      handleEdit,
      handleDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.patch-ma-in-operate {
  .el-table {
    height: calc(100% - 95px);
  }
}
</style>
