<template>
<el-dialog v-model="visible" :title="title" custom-class="patch-update-dialog" :close-on-click-modal="false" :before-close="close">
  <el-form :model="form" ref="ruleFormRef" :rules="rules" v-loading="loading">
    <el-form-item label="补丁名称：" prop="txtPatchName">
      <el-input v-model="form.txtPatchName" placeholder="请填写补丁名称，最多20字符" maxlength="20" />
    </el-form-item>
    <el-form-item label="所属设备：" prop="deviceTypeIds">
      <el-select v-model="form.deviceTypeIds" multiple collapse-tags collapse-tags-tooltip placeholder="请选择所属设备，可多选">
        <el-option v-for="(sItem, sIndex) in options.deviceOption" :key="sIndex" :label="sItem.txtTypeName" :value="sItem.deviceTypeId" />
      </el-select>
    </el-form-item>
    <el-form-item label="内容说明：" prop="txtPatchDesc">
      <el-input v-model="form.txtPatchDesc" maxlength="100" :autosize="{minRows: 10, maxRows: 20}" show-word-limit type="textarea" placeholder="请填写关于当前补丁的解释说明，最多100字符" />
    </el-form-item>
    <el-form-item label="下载地址：" prop="txtDownloadUrl">
      <el-input v-model="form.txtDownloadUrl" placeholder="请输入安装包下载地址" />
    </el-form-item>
    <el-form-item label="安装教程：" prop="txtInstallationTutorial">
      <el-upload action="#" :show-file-list="false" :on-change="picChange" :auto-upload="false">
        <div class="el-upload__item" v-if="form.txtInstallationTutorial">
          <img :src="form.txtInstallationTutorial" alt="" />
          <span class="el-upload__item-actions">
            <span class="el-upload__item-preview" @click.stop="handleImgPreview">
              <el-icon>
                <zoom-in />
              </el-icon>
            </span>
            <span class="el-upload__item-delete" @click.stop="handleRemove">
              <el-icon>
                <Delete />
              </el-icon>
            </span>
          </span>
        </div>
        <el-icon v-else>
          <Plus />
        </el-icon>
      </el-upload>

      <el-dialog v-model="imgPreVisible">
        <img w-full :src="dialogImageUrl" alt="Preview Image" />
      </el-dialog>
    </el-form-item>
  </el-form>
  <template #footer>
    <div class="dialog-footer">
      <el-button type="info" @click="close('cancel')">取 消</el-button>
      <el-button type="primary" @click="close('confirm')">确 认</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 运营管理 - 补丁程序管理 - 新增/编辑
// todo 控制图片格式
// todo 提交函数做回调
import { useStore } from 'vuex';
import { ElMessage, FormInstance } from "element-plus";
import { ZoomIn, Delete, Plus } from "@element-plus/icons-vue";
import { reactive, toRefs, computed, onMounted, ref } from "vue";
import { getDeviceTypeList, getOSSParams, addPatch, getPatchDetail, updatePatch } from "api/apis.js";
export default {
  props: {
    updateData: {
      type: Object,
      default: function () {
        return { type: "add" };
      }
    },
  },
  components: {
    Plus,
    ZoomIn,
    Delete,
  },
  setup(prop, ctx) {
    const OSS = require("ali-oss");
    const moment = require("moment");
    const store = useStore();

    // 表单校验 - 下载地址格式
    const urlPattern = (rule, value, callback) => {
      let result = value.replace(/\s/g, ""),
        regex = /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/;
      if (regex.test(result)) {
        callback();
      } else {
        callback("下载链接格式不正确");
      }
    };

    const state = reactive({
      UID: computed(() => store.getters.UUID),
      ruleFormRef: ref(FormInstance),
      visible: true,
      loading: false,
      imgPreVisible: false,
      dialogImageUrl: "",
      title: computed(() => prop.updateData.type == "edit" ? "编辑补丁 - " + prop.updateData.txtPatchName : "新增补丁"),
      form: {
        txtPatchName: "",
        deviceTypeIds: [],
        txtPatchDesc: "",
        txtInstallationTutorial: "",
        txtDownloadUrl: "",
        file: {}
      },
      oldForm: {},
      rules: {
        txtPatchName: [{ required: true, message: "补丁名称不可为空", trigger: "blur" }],
        deviceTypeIds: [{ required: true, message: "所属设备不可为空", trigger: "blur" }],
        txtPatchDesc: [{ required: true, message: "内容说明不可为空", trigger: "blur" }],
        txtDownloadUrl: [
          { required: true, message: "下载地址不可为空", trigger: "blur" },
          { required: true, validator: urlPattern, trigger: "blur" }
        ]
      },
      options: {
        deviceOption: []
      }
    });

    onMounted(() => {
      getDeviceOption();
      // 编辑情况 获取详情
      if (prop.updateData.type == "edit") {
        getDetail();
      }
    })

    // 获取详情
    const getDetail = () => {
      state.loading = true;
      getPatchDetail({ patchId: prop.updateData.patchId }).then(response => {
        if (response.code == 200) {
          state.form = response.data;
          state.oldForm = JSON.parse(JSON.stringify(state.form));
        } else {
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 文件状态改变时
    const picChange = file => {
      // 判断文件类型
      let regex = /^(?:jpg|jpeg|png|gif|bmp)$/i,
        tempArr = file.name.split("."),
        fileName = tempArr[tempArr.length - 1];
      if (regex.test(fileName)) {
        state.form.txtInstallationTutorial = URL.createObjectURL(file.raw);
        state.form.file = file;
      } else {
        ElMessage.error("当前上传的文件非图片格式，请重新上传");
      }
    };

    // 预览图片
    const handleImgPreview = () => {
      state.dialogImageUrl = state.form.txtInstallationTutorial;
      state.imgPreVisible = true;
    };

    // 删除图片
    const handleRemove = () => {
      state.form.txtInstallationTutorial = "";
    };

    // 获取设备 option
    const getDeviceOption = () => {
      state.loading = true;
      getDeviceTypeList().then(response => {
        if (response.code == 200) {
          state.options.deviceOption = response.data;
        } else {
          ElMessage.error(response.message);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    const close = type => {
      if (type == "confirm") {
        // 必填校验
        state.ruleFormRef.validate(valid => {
          if (valid) {
            // 判断图片状态，有图片，先上传
            if (state.form.txtInstallationTutorial != "") {
              if (prop.updateData.type == "edit") {
                if (state.form.txtInstallationTutorial != state.oldForm.txtInstallationTutorial) {
                  state.loading = true;

                  // 获取 OSS 各种参数
                  getOSSParams({ bizType: 1 }).then(response => {
                    if (response.code == 200) {
                      // 创建client
                      const client = new OSS({
                        region: response.data.region,
                        accessKeyId: response.data.accessKeyId,
                        accessKeySecret: response.data.accessKeySecret,
                        bucket: response.data.bucketName,
                        stsToken: response.data.securityToken,
                        endpoint: response.data.endpoint,
                      })

                      // 文件夹名称，按日期规划
                      let endWith = state.form.file.name.split("."),
                        // 默认路径 + 当前日期 + UUID + 文件格式
                        dir = response.data.filePath + "/" + moment(new Date()).format('YYYY-MM-DD') + "/" + state.UID + "." + endWith[endWith.length - 1];
                      // 上传
                      client.put(dir, state.form.file.raw).then(res => {
                        if (res.res.status == 200) {
                          state.form.txtInstallationTutorial = res.url;

                          // 调用提交接口
                          handleSubmit().then(response => {
                            if (response.code == 200) {
                              ElMessage.success("操作成功");
                              ctx.emit("close", true);
                            }
                          }).catch(e => {
                            console.error(e);
                          }).finally(() => {
                            state.loading = false;
                          });
                        } else {
                          console.error(res);
                        }
                      }).catch(e => {
                        console.error(e);
                      })
                    } else {
                      ElMessage.error(response.message);
                    }
                  }).catch(e => {
                    console.error(e);
                  }).finally(() => {
                    state.loading = false;
                  })
                } else {
                  // 调用提交接口
                  handleSubmit().then(response => {
                    if (response.code == 200) {
                      ElMessage.success("操作成功");
                      ctx.emit("close", true);
                    }
                  }).catch(e => {
                    console.error(e);
                  }).finally(() => {
                    state.loading = false;
                  });
                }
              } else {
                state.loading = true;

                // 获取 OSS 各种参数
                getOSSParams({ bizType: 1 }).then(response => {
                  if (response.code == 200) {
                    // 创建client
                    const client = new OSS({
                      region: response.data.region,
                      accessKeyId: response.data.accessKeyId,
                      accessKeySecret: response.data.accessKeySecret,
                      bucket: response.data.bucketName,
                      stsToken: response.data.securityToken,
                      endpoint: response.data.endpoint,
                    })

                    // 文件夹名称，按日期规划
                    let endWith = state.form.file.name.split("."),
                      // 默认路径 + 当前日期 + UUID + 文件格式
                      dir = response.data.filePath + "/" + moment(new Date()).format('YYYY-MM-DD') + "/" + state.UID + "." + endWith[endWith.length - 1];
                    // 上传
                    client.put(dir, state.form.file.raw).then(res => {
                      if (res.res.status == 200) {
                        state.form.txtInstallationTutorial = res.url;

                        // 调用提交接口
                        handleSubmit().then(response => {
                          if (response.code == 200) {
                            ElMessage.success("操作成功");
                            ctx.emit("close", true);
                          }
                        }).catch(e => {
                          console.error(e);
                        }).finally(() => {
                          state.loading = false;
                        });
                      } else {
                        console.error(res);
                      }
                    }).catch(e => {
                      console.error(e);
                    })
                  } else {
                    ElMessage.error(response.message);
                  }
                }).catch(e => {
                  console.error(e);
                }).finally(() => {
                  state.loading = false;
                })
              }
            } else {
              // 调用提交接口
              handleSubmit().then(response => {
                if (response.code == 200) {
                  ElMessage.success("操作成功");
                  ctx.emit("close", true);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              });
            }
          }
        })
      } else {
        ctx.emit("close", false);
      }
    }

    // 提交
    const handleSubmit = () => {
      return new Promise((reduce, reject) => {
        // 上传成功，调用 新增/编辑 接口
        if (prop.updateData.type == "add") {
          // 调用 新增 接口
          state.form.txtCreator = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
          addPatch(state.form).then(resp => {
            if (resp.code == 200) {
              reduce({ code: 200 });
            } else {
              reject({ message: resp.message });
            }
          }).catch(e => {
            reject({ message: e });
            console.error(e);
          })
        } else {
          // 调用 编辑 接口
          state.form.txtUpdater = store.getters.userInfo ? store.getters.userInfo.name : "系统用户";
          updatePatch(state.form).then(resp => {
            if (resp.code == 200) {
              reduce({ code: 200 });
            } else {
              reject({ message: resp.message });
            }
          }).catch(e => {
            reject({ message: e });
            console.error(e);
          })
        }
      })
    };

    return {
      ...toRefs(state),
      close,
      getDeviceOption,
      picChange,
      handleImgPreview,
      handleRemove,
      handleSubmit,
      getDetail
    }
  }
}
</script>

<style lang="scss">
.patch-update-dialog {
  .el-form {
    .el-form-item {
      &:last-child {
        .el-form-item__label {
          padding-left: 11px;
        }
      }

      .el-form-item__label {
        width: 110px;
        text-align: left;
      }

      .el-form-item__content {
        .el-select {
          width: 100%;
        }

        .el-dialog {
          .el-dialog__header {
            box-shadow: none;
          }

          .el-dialog__body {
            height: calc(100% - 30px);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .el-upload {
          border: 1px dashed var(--el-border-color);
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          transition: 0.5s;

          .el-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            text-align: center;
          }

          .el-upload__item {
            width: 178px;
            height: 178px;

            transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
            font-size: 14px;
            color: var(--el-text-color-regular);
            margin-bottom: 5px;
            position: relative;
            box-sizing: border-box;
            border-radius: 4px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            .el-upload__item-actions {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              cursor: default;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              opacity: 0;
              font-size: 20px;
              background-color: var(--el-overlay-color-lighter);
              transition: opacity var(--el-transition-duration);

              &:hover {
                opacity: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;

                span {
                  display: inline-flex;
                }
              }

              .el-icon {
                color: inherit;
                height: 1.5rem;
                width: 1.5rem;
                line-height: 1rem;
                display: inline-flex;
              }

              span {
                display: none;
                cursor: pointer;
              }

              .el-upload__item-delete {
                position: static;
                font-size: inherit;
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
}
</style>
